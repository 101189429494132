import React from "react"
import Sensor, { displayNameForSensor } from "../../models/sensor"
import Placement from "../../models/placement"
import { calculateStrength, maturityForSensor } from "../../utilities/maturity"
import "chartjs-adapter-moment"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js"
import annotationPlugin from "chartjs-plugin-annotation"
import { Line } from "react-chartjs-2"
import { elapsedTimeTooltip } from "./tooltips"
import moment from "moment"

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
)

interface Props {
  sensors: Sensor[]
  placement: Placement
  colorMap: Map<number, string>
  startDate?: moment.Moment
  endDate?: moment.Moment
  title?: string
}

const StrengthChart = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const annotations: { [name: string] : any } = {}

  const maxGoalStrengthValue = props.placement.goals.map(goal => calculateStrength(props.placement, goal.value) ?? 0).reduce((a, b) => Math.max(a, b), 0)

  props.placement.goals.forEach((goal) => {
    if (goal.enabled === false) {
      return
    }
    annotations[goal.name] = {
      type: "line",
      mode: "horizontal",
      scaleID: "y",
      value: calculateStrength(props.placement, goal.value),
      borderColor: "black",
      borderWidth: 2,
      borderDash: [6, 6],
      label: {
        color: "black",
        content: goal.name,
        display: true,
        backgroundColor: "#fff0",
        position: "end" as const,
        yAdjust: -10
      }
    }
  })

  let strengthUnit = ""
  const unit = props.placement.maturityMethod.curveFit.parameters?.strengthUnit
  if (unit === "mpa") {
    strengthUnit = "MPa"
  } else {
    strengthUnit = "psi"
  }

  const options = {
    animation: {
      duration: 0
    },
    responsive: true,
    spansGaps: false,
    maintainAspectRatio: false,
    pointRadius: 1,
    plugins: {
      tooltip: {
        callbacks: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          title: (context: any) => {
            return moment(context[0].parsed.x).format("MM/DD/YY H:mm")
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          label: (context: any) => {
            if (strengthUnit === "MPa") {
              return `${context.parsed.y.toLocaleString(undefined, {maximumFractionDigits: 2})} ${strengthUnit}`
            } else {
              return `${context.parsed.y.toLocaleString(undefined, {maximumFractionDigits: 0})} ${strengthUnit}`
            }
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          footer: (tooltipItems: any) => {
            const sensorName = tooltipItems[0].dataset.label
            const sensor = props.sensors.find(sensor => displayNameForSensor(sensor) === sensorName)
            if (!sensor) {
              return ""
            }

            return elapsedTimeTooltip(tooltipItems, moment(tooltipItems[0].raw.x), moment(new Date(sensor.placementDate).setSeconds(0, 0)))
          }
        }
      },
      legend: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick: (e: any) => e.stopPropagation(),
        display: true
      },
      title: {
        display: true,
        text: props.title ?? "Strength"
      },
      annotation: {
        annotations: annotations
      }
    },
    scales: {
      x: {
        offset: true,
        type: "time" as const,
        time: {
          displayFormats: {
            millisecond: "MM/DD/YY H:mm",
            second: "MM/DD/YY H:mm",
            minute: "MM/DD/YY H:mm",
            hour: "MM/DD/YY H:mm",
            day: "MM/DD/YY H:mm",
            week: "MM/DD/YY H:mm",
            month: "MM/DD/YY H:mm",
            quarter: "MM/DD/YY H:mm",
            year: "MM/DD/YY H:mm"
          }
        },
        gridLines: {
          display: true
        },
        ticks: {
          maxTicksLimit: 6
        }
      },
      y: {
        min: 0,
        suggestedMax: Math.max(maxGoalStrengthValue, strengthUnit == "MPa" ? 7 : 1000),
        ticks: {
          callback: function(label: string | number | undefined) {
            let formatedLabel = label
            if (strengthUnit === "MPa") {
              if (typeof label === "number") {
                formatedLabel = label.toLocaleString(undefined, {maximumFractionDigits: 2})
              } else if (typeof label === "string") {
                formatedLabel = parseFloat(label).toLocaleString(undefined, {maximumFractionDigits: 2})
              }
            } else {
              if (typeof label === "number") {
                formatedLabel = label.toLocaleString(undefined, {maximumFractionDigits: 0})
              } else if (typeof label === "string") {
                formatedLabel = parseInt(label.split(".")[0]).toLocaleString(undefined, {maximumFractionDigits: 0})
              }
            }
            return formatedLabel + " " + strengthUnit
          }
        }
      }
    }
  }

  const dataForSensor = (sensor: Sensor) => {
    const maturity = maturityForSensor(sensor, props.placement, props.startDate?.toDate(), props.endDate?.toDate())
    const strengthValues = maturity.map((maturity) => {
      return {
        x: maturity.sample.time,
        y: maturity.strengthValue
      }
    })
    return strengthValues
  }

  const data = {
    datasets: props.sensors.map((sensor: Sensor) => {
      return {
        label: displayNameForSensor(sensor),
        data: dataForSensor(sensor),
        borderColor: props.colorMap.get(sensor.id),
        backgroundColor: props.colorMap.get(sensor.id),
        borderWidth: 1
      }
    })
  }

  return (
    <Line data={data} options={options} width="100%" height="100%" />
  )
}

export default StrengthChart
