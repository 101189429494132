import { calculateStrengthFromMaturity } from "../utilities/maturity"
import { MaturityMethodEntry } from "./maturitymethod"

export type Goal = {
  id: number
  name: string
  value: number
  enabled: boolean
  updatedAt: Date
}

export type GoalEntry = {
  id: number | null
  name: string | null
  value: number | null
}

export const convertedGoalMaturityValue = (goal: Goal, maturityMethodEntry: MaturityMethodEntry): number => {
  if (maturityMethodEntry.type === "arrhenius") {
    return goal.value
  } else {
    if (maturityMethodEntry.temperatureUnit === "c") {
      return goal.value
    } else {
      return goal.value * 9/5
    }
  }
}

export const convertedGoalValue = (goal: Goal, maturityMethodEntry: MaturityMethodEntry): number => {
  if (maturityMethodEntry.curveFit.type === "disabled") {
    return convertedGoalMaturityValue(goal, maturityMethodEntry)
  } else {
    if (maturityMethodEntry.type === "arrhenius") {
      return calculateStrengthFromMaturity(maturityMethodEntry, goal.value) ?? goal.value
    } else {
      if (maturityMethodEntry.temperatureUnit === "c") {
        return calculateStrengthFromMaturity(maturityMethodEntry, goal.value) ?? goal.value
      } else {
        return calculateStrengthFromMaturity(maturityMethodEntry, goal.value * 9/5) ?? goal.value
      }
    }
  }
}

export const convertToGoalEntry = (goal: Goal, maturityMethodEntry: MaturityMethodEntry): GoalEntry => {
  const value = convertedGoalValue(goal, maturityMethodEntry)

  return {
    id: goal.id,
    name: goal.name,
    value: value
  }
}

export const isGoalEntryEqual = (a: GoalEntry, b: GoalEntry) => {
  return a.name === b.name
    && a.value === b.value
}