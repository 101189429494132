import React from "react"
import Sensor from "../../../models/sensor"
import Project from "../../../models/project"
import Placement from "../../../models/placement"
import { maximumSampleDate, minimumSampleDate } from "../../../utilities/samples"
import { createAndDownloadPDF, ReportPreviewView } from "../TemperatureDifferential/TemperatureDifferentialReportPreviewView"
import { Box, Typography } from "@mui/material"
import SensorSummaryTableView from "./SensorSummaryTableView"
import SensorInfoTableView from "./SensorInfoTableView"
import MaturityInfoTableView from "./MaturityInfoTableView"
import StrengthChart from "../../charts/StrengthChart"
import MaturityChart from "../../charts/MaturityChart"
import TemperatureChart from "../../charts/TemperatureChart"
import { logExport } from "../../../utilities/analytics"
import moment from "moment"
import domtoimage from "dom-to-image"

interface Props {
  project: Project
  placement: Placement
  selectedSensors: Sensor[]
  colorMap: Map<number, string>
  onClose: () => void
}


const SelectedSensorsReportPreviewView = (props: Props) => {
  const startDate = minimumSampleDate(props.selectedSensors)
  const endDate = maximumSampleDate(props.selectedSensors)

  if (!startDate || !endDate) {
    return ( <></> )
  }

  const pdfMargins = 8
  const hasStrength = props.placement.maturityMethod.curveFit.type !== "disabled"
  const hasMaturity = props.placement.maturityMethod.type !== "disabled"

  return (
    <ReportPreviewView
      onClose={props.onClose}
      onSave={() => {
        const generateRport = async () => {
          
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const content: any = await Promise.all(props.selectedSensors.flatMap(async (sensor) => {
            const sensorSummary = document.getElementById(`sensor-summary-table-${sensor.id}`)
            const sensorSummaryTable = await domtoimage.toJpeg(sensorSummary as HTMLElement)
            
            const sensorInfo = document.getElementById(`sensor-info-table-${sensor.id}`)
            const sensorInfoTable = await domtoimage.toJpeg(sensorInfo as HTMLElement)

            const maturityInfo = document.getElementById(`maturity-info-table-${sensor.id}`)
            const maturityInfoTable = await domtoimage.toJpeg(maturityInfo as HTMLElement)

            const sensorContent = [
              {
                text: sensor.name,
                margin: pdfMargins,
                fontSize: 16,
                bold: true
              },
              {
                image: sensorSummaryTable,
                width: 500,
                margin: pdfMargins,
              },
              {
                image: sensorInfoTable,
                width: 300,
                margin: pdfMargins
              },
              {
                image: maturityInfoTable,
                width: 300,
                margin: pdfMargins
              }
            ]

            if (hasStrength) {
              const strengthChart = document.getElementById(`strength-chart-${sensor.id}`)
              const strengthChartImage = await domtoimage.toPng(strengthChart as HTMLElement)
              sensorContent.push({
                image: strengthChartImage,
                width: 500,
                margin: pdfMargins
              })
            }

            if (hasMaturity) {
              const maturityChart = document.getElementById(`maturity-chart-${sensor.id}`)
              const maturityChartImage = await domtoimage.toPng(maturityChart as HTMLElement)
              sensorContent.push({
                image: maturityChartImage,
                width: 500,
                margin: pdfMargins
              })
            }

            const temperatureChart = document.getElementById(`temperature-chart-${sensor.id}`)
            const temperatureChartImage = await domtoimage.toPng(temperatureChart as HTMLElement)
            sensorContent.push({
              image: temperatureChartImage,
              width: 500,
              margin: pdfMargins
            })

            return sensorContent
          }))

          if (props.selectedSensors.length > 1) {
            const combinedTemperatureChart = document.getElementById("combined-temperature-chart")
            const combinedTemperatureChartImage = await domtoimage.toPng(combinedTemperatureChart as HTMLElement)
            content.push({
              image: combinedTemperatureChartImage,
              width: 500,
              margin: pdfMargins
            })

            if (hasMaturity) {
              const combinedMaturityChart = document.getElementById("combined-maturity-chart")
              const combinedMaturityChartImage = await domtoimage.toPng(combinedMaturityChart as HTMLElement)
              content.push({
                image: combinedMaturityChartImage,
                width: 500,
                margin: pdfMargins
              })
            }

            if (hasStrength) {
              const combinedStrengthChart = document.getElementById("combined-strength-chart")
              const combinedStrengthChartImage = await domtoimage.toPng(combinedStrengthChart as HTMLElement)
              content.push({
                image: combinedStrengthChartImage,
                width: 500,
                margin: pdfMargins
              })
            }
          }

          const title = `${props.project.name} > ${props.placement.name}`
          const filename = props.placement.name + "_" + moment().format("YYYYMMDD[T]HHmmss") + ".pdf"
          createAndDownloadPDF(content, filename, title)
          logExport("selected_sensors", props.selectedSensors.length)
        }

        generateRport()
      }} 
    >
      {props.selectedSensors.map(sensor => {
        return (
          <>
            <Typography id={`sensor-name-${sensor.id}`} variant="h6" sx={{
              marginBottom: "12px"
            }}>
              {sensor.name}
            </Typography>
            <SensorSummaryTableView
              id={`sensor-summary-table-${sensor.id}`}
              sensor={sensor}
              placement={props.placement}
            />
            <SensorInfoTableView
              id={`sensor-info-table-${sensor.id}`}
              sensor={sensor}
              placement={props.placement}
            />
            <MaturityInfoTableView
              id={`maturity-info-table-${sensor.id}`}
              maturityMethod={props.placement.maturityMethod}
            />
            {hasStrength &&
              <Box id={`strength-chart-${sensor.id}`} sx={{
                width: "100%",
                height: 575,
                backgroundColor: "#ffffff"
              }}>
                <StrengthChart
                  sensors={[sensor]}
                  placement={props.placement}
                  colorMap={props.colorMap}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Box>
            }
            {hasMaturity &&
              <Box id={`maturity-chart-${sensor.id}`} sx={{
                width: "100%",
                height: 575,
                backgroundColor: "#ffffff"
              }}>
                <MaturityChart
                  sensors={[sensor]}
                  placement={props.placement}
                  colorMap={props.colorMap}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Box>
            }
            <Box id={`temperature-chart-${sensor.id}`} sx={{
              width: "100%",
              height: 575,
              backgroundColor: "#ffffff"
            }}>
              <TemperatureChart
                forReport={true}
                temperatureUnit={props.placement.temperatureUnit}
                sensors={[sensor]}
                colorMap={props.colorMap}
                timezone={props.placement.timeZone}
                min={props.placement.temperatureLimit?.minimumTemperature ?? undefined}
                max={props.placement.temperatureLimit?.maximumTemperature ?? undefined}
                startDate={startDate}
                endDate={endDate}
              />
            </Box>
          </>
        )
      })
      }
      {props.selectedSensors.length > 1 &&
        <Box id="combined-temperature-chart" sx={{
          width: "100%",
          minHeight: 1000,
          backgroundColor: "#ffffff"
        }}>
          <TemperatureChart
            forReport={true}
            temperatureUnit={props.placement.temperatureUnit}
            sensors={props.selectedSensors}
            colorMap={props.colorMap}
            timezone={props.placement.timeZone}
            min={props.placement.temperatureLimit?.minimumTemperature ?? undefined}
            max={props.placement.temperatureLimit?.maximumTemperature ?? undefined}
            startDate={startDate}
            endDate={endDate}
            title={`Combined Temperature (${props.placement.temperatureUnit === "c" ? "°C" : "°F"})`}
          />
        </Box>
      }
      {hasMaturity && props.selectedSensors.length > 1 &&
        <Box id="combined-maturity-chart" sx={{
          width: "100%",
          minHeight: 1000,
          backgroundColor: "#ffffff"
        }}>
          <MaturityChart
            sensors={props.selectedSensors}
            placement={props.placement}
            colorMap={props.colorMap}
            startDate={startDate}
            endDate={endDate}
            title={`Combined TTF (${props.placement.maturityMethod.temperatureUnit === "c" ? "°C" : "°F"})`}
          />
        </Box>
      }
      {hasStrength && props.selectedSensors.length > 1 &&
        <Box id="combined-strength-chart" sx={{
          width: "100%",
          minHeight: 1000,
          backgroundColor: "#ffffff"
        }}>
          <StrengthChart
            sensors={props.selectedSensors}
            placement={props.placement}
            colorMap={props.colorMap}
            startDate={startDate}
            endDate={endDate}
            title={`Combined Strength (${props.placement.maturityMethod.curveFit.parameters?.strengthUnit})`}
          />
        </Box>
      }
    </ReportPreviewView>
  )
}

export default SelectedSensorsReportPreviewView