import React from "react"
import Placement, { convertToPlacementEntry, PlacementEntry } from "../../../models/placement"
import MaturityMethod, { MaturityParametersEntry } from "../../../models/maturitymethod"
import { CurveFitEntry, CurveFitParametersEntry } from "../../../models/curvefit"
import { convertToTemperatureLimitEntry, isTemperatureLimitEntryEqual, TemperatureLimitEntry } from "../../../models/temperaturelimit"
import { useAuth } from "../../auth/AuthProvider"
import { createCurveFit, createGoal, createMaturityMethod, createTemperatureLimit, deleteGoal, getPlacement, updateCurveFit, updateGoal, updateMaturityMethod, updatePlacement, updateTemperatureLimit } from "../../../network/network"
import { useAppSelector } from "../../../store/hooks"
import PlacementSpecForm, { celsiusBasedMaturityMethodParams, mpaCelsiusBasedCurveFitParams } from "./PlacementSpecsForm"
import SampleUnit from "../../../models/units"
import { GoalEntry } from "../../../models/goal"
import { uniqueString } from "../../../utilities/stringcasing"
import Project from "../../../models/project"

interface Props {
  placement: Placement
  project: Project
  onPlacementUpdate: (placement: Placement) => void
  onClose: () => void
  onEntryChange: (entry: PlacementEntry) => void
}

const PlacementSpecificationsView = (props: Props) => {
  const { user } = useAuth()
  const accessToken = useAppSelector(state => state.user.token)
  const [savingPlacement, setSavingPlacement] = React.useState(false)
  const [error, setError] = React.useState<boolean>(false)
  const [entry, setEntry] = React.useState<PlacementEntry>(convertToPlacementEntry(props.placement))

  const updateEntry = (entry: PlacementEntry) => {
    setEntry(entry)
    props.onEntryChange(entry)
  }

  const savePlacement = async (    
    name: string | null, 
    timeZone: string, 
    temperatureUnit: SampleUnit, 
    temperatureLimitParams: TemperatureLimitEntry,
    maturityMethodType: MaturityMethod["type"],
    maturityMethodTemperatureUnit: SampleUnit,
    maturityMethodParams: MaturityParametersEntry,
    curveFitType: CurveFitEntry["type"],
    curveFitParams: CurveFitParametersEntry,
    goalEntries: GoalEntry[]
  ) => {
    if (!accessToken) {
      return
    }

    let finalName = name
    if (!finalName) {
      finalName = ""
    }

    if (finalName === "") {
      finalName = "My Placement"
    }

    if (finalName !== props.placement.name) {
      const placementsInProject = props.project.placements.filter(p => p.id !== props.placement.id)
      finalName = uniqueString(finalName, placementsInProject.map(p => p.name) ?? [])
    }

    setSavingPlacement(true)

    const temperatureLimitRequiresUpdate = !isTemperatureLimitEntryEqual(temperatureLimitParams, convertToTemperatureLimitEntry(props.placement.temperatureLimit, "c"))
    
    const originalCelsiusBasedMaturityMethodParams = celsiusBasedMaturityMethodParams(convertToPlacementEntry(props.placement))
    const maturityMethodRequiresUpdate = (maturityMethodTemperatureUnit !== props.placement.maturityMethod.temperatureUnit && maturityMethodType !== "disabled") || 
      maturityMethodType !== props.placement.maturityMethod.type || 
      maturityMethodParams.activationEnergy !== originalCelsiusBasedMaturityMethodParams.activationEnergy ||
      maturityMethodParams.referenceTemperature !== originalCelsiusBasedMaturityMethodParams.referenceTemperature ||
      maturityMethodParams.datumTemperature !== originalCelsiusBasedMaturityMethodParams.datumTemperature

    const originalMpaCelsiusBasedCurveFitParams = mpaCelsiusBasedCurveFitParams(convertToPlacementEntry(props.placement))
    const curveFitRequiresUpdate = curveFitType !== props.placement.maturityMethod.curveFit.type ||
      curveFitParams.slope !== originalMpaCelsiusBasedCurveFitParams.slope ||
      curveFitParams.intercept !== originalMpaCelsiusBasedCurveFitParams.intercept ||
      curveFitParams.kValue !== originalMpaCelsiusBasedCurveFitParams.kValue ||
      curveFitParams.ultimateStrength !== originalMpaCelsiusBasedCurveFitParams.ultimateStrength ||
      curveFitParams.initialMaturity !== originalMpaCelsiusBasedCurveFitParams.initialMaturity ||
      curveFitParams.initialEquivalentAge !== originalMpaCelsiusBasedCurveFitParams.initialEquivalentAge ||
      (curveFitParams.strengthUnit !== props.placement.maturityMethod.curveFit.parameters?.strengthUnit && curveFitType !== "disabled")

    let temperatureLimitId = props.placement.temperatureLimit.id
    let maturityMethodId = props.placement.maturityMethod.id
    let curveFitId = props.placement.maturityMethod.curveFit.id

    const goalsToCreate = goalEntries.filter(goal => goal.id === null)
    const goalsToUpdate = goalEntries.filter(goal => {
      const goalInPlacement = props.placement.goals.find(g => g.id === goal.id)
      if (!goalInPlacement) {
        return false
      } else {
        return goal.name !== goalInPlacement.name || goal.value !== goalInPlacement.value
      }
    })
    const goalsToDelete = props.placement.goals.filter(goal => !goalEntries.some(g => g.id === goal.id))

    try {
      if (temperatureLimitRequiresUpdate) {
        if (props.placement.temperatureLimit.ownerId === user?.identifier) {
          await updateTemperatureLimit(accessToken, props.placement.temperatureLimit.id, temperatureLimitParams.minimumTemperature, temperatureLimitParams.maximumTemperature, temperatureLimitParams.difference)
        } else {
          const newTemperatureLimit = await createTemperatureLimit(accessToken, temperatureLimitParams.minimumTemperature, temperatureLimitParams.maximumTemperature, temperatureLimitParams.difference)

          temperatureLimitId = newTemperatureLimit.id       
        }
      }

      if (curveFitRequiresUpdate) {
        if (props.placement.maturityMethod.curveFit.ownerId === user?.identifier) {
          await updateCurveFit(accessToken, curveFitId, curveFitType, curveFitParams)
        } else {
          const newCurveFit = await createCurveFit(accessToken, curveFitType, curveFitParams)

          curveFitId = newCurveFit.id
        }
      }

      if (maturityMethodRequiresUpdate || curveFitRequiresUpdate) {
        if (props.placement.maturityMethod.ownerId === user?.identifier) {
          await updateMaturityMethod(accessToken,props.placement.maturityMethod.id, maturityMethodType, maturityMethodTemperatureUnit, maturityMethodParams, curveFitId)
        } else {
          const newMaturityMethod = await createMaturityMethod(accessToken, maturityMethodType, maturityMethodTemperatureUnit, maturityMethodParams, curveFitId)
          
          maturityMethodId = newMaturityMethod.id
        }
      }

      await updatePlacement(accessToken, props.project.id, props.placement.id, finalName, timeZone, temperatureUnit, temperatureLimitId, maturityMethodId)

      for (const goal of goalsToDelete) {
        if (goal.id) {
          await deleteGoal(accessToken, props.project.id, props.placement.id, goal.id)
        }
      }

      for (const goal of goalsToCreate) {
        if (goal.value) {
          await createGoal(accessToken, props.project.id, props.placement.id, goal.name ?? "", goal.value)
        }
      }

      for (const goal of goalsToUpdate) {
        if (goal.value && goal.id) {
          await updateGoal(accessToken, props.project.id, props.placement.id, goal.id, goal.name ?? "", goal.value)
        }
      }

      const placement = await getPlacement(accessToken, props.project.id, props.placement.id)

      props.onPlacementUpdate(placement)
    } catch (e) {
      setError(true)
      console.log(e)
    } finally {
      setSavingPlacement(false)
    }
  }

  return (
    <PlacementSpecForm entry={entry} onEntryChange={updateEntry} onSave={savePlacement} isLoading={savingPlacement} hasError={error} onClose={props.onClose}/>
  )
}

export default PlacementSpecificationsView
