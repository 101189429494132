import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, styled, tableCellClasses } from "@mui/material"
import React from "react"
import MaturityMethod, { ArrheniusParameters, NurseSaulParameters } from "../../../models/maturitymethod"
import { convertedTemperatureValue } from "../../../utilities/calculations"

interface Props {
  maturityMethod: MaturityMethod
  id: string
}

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#AAAAAA",
  },
}))

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 6
  },
}))

const MaturityInfoTableView = (props: Props) => {
  if (props.maturityMethod.type === "disabled") {
    return <></>
  }

  return (
    <TableContainer id={props.id} component={Paper} sx={{
      width: "60%",
      overflow: "hidden"
    }}>
      <Table sx={{
        border: "2px solid black"
      }}>
        {props.maturityMethod.type === "arrhenius" && 
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row" sx={{
                backgroundColor: "#7F7F7F",
                borderRight: "1px solid black",
              }}>
                Maturity Method
              </StyledTableCell>
              <StyledTableCell align="right">Arrhenius</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row" sx={{
                backgroundColor: "#7F7F7F",
                borderRight: "1px solid black",
              }}>
                Reference Temp ({props.maturityMethod.temperatureUnit === "c" ? "°C" : "°F"})
              </StyledTableCell>
              <StyledTableCell align="right">{convertedTemperatureValue((props.maturityMethod.parameters as ArrheniusParameters).referenceTemperature, props.maturityMethod.temperatureUnit).toFixed(1)}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row" sx={{
                backgroundColor: "#7F7F7F",
                borderRight: "1px solid black",
              }}>
                Activation Energy (J/mol)
              </StyledTableCell>
              <StyledTableCell align="right">{(props.maturityMethod.parameters as ArrheniusParameters).activationEnergy}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        }
        {props.maturityMethod.type === "nurse saul" &&
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row" sx={{
                backgroundColor: "#7F7F7F",
                borderRight: "1px solid black",
              }}>
                Maturity Method
              </StyledTableCell>
              <StyledTableCell align="right">Nurse-Saul</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row" sx={{
                backgroundColor: "#7F7F7F",
                borderRight: "1px solid black",
              }}>
                Datum Temp ({props.maturityMethod.temperatureUnit === "c" ? "°C" : "°F"})
              </StyledTableCell>
              <StyledTableCell align="right">{convertedTemperatureValue((props.maturityMethod.parameters as NurseSaulParameters).datumTemperature, props.maturityMethod.temperatureUnit).toFixed(1)}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        }
      </Table>
    </TableContainer>
  )
}

export default MaturityInfoTableView