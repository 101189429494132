import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, styled, tableCellClasses } from "@mui/material"
import React from "react"
import Sensor from "../../../models/sensor"
import moment from "moment"
import Placement from "../../../models/placement"

interface Props {
  sensor: Sensor
  placement: Placement
  id: string
}

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#AAAAAA",
  },
}))

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 6
  },
}))

const SensorInfoTableView = (props: Props) => {
  return (
    <TableContainer id={props.id} component={Paper} sx={{
      width: "60%",
      overflow: "hidden"
    }}>
      <Table sx={{
        border: "2px solid black"
      }}>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" sx={{
              backgroundColor: "#7F7F7F",
              borderRight: "1px solid black",
            }}>
              Name
            </StyledTableCell>
            <StyledTableCell align="right">{props.sensor.name}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" sx={{
              backgroundColor: "#7F7F7F",
              borderRight: "1px solid black",
            }}>
              Serial Number
            </StyledTableCell>
            <StyledTableCell align="right">{props.sensor.serial}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" sx={{
              backgroundColor: "#7F7F7F",
              borderRight: "1px solid black",
            }}>
              Location
            </StyledTableCell>
            <StyledTableCell align="right">{props.sensor.location}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" sx={{
              backgroundColor: "#7F7F7F",
              borderRight: "1px solid black",
            }}>
              Depth
            </StyledTableCell>
            <StyledTableCell align="right">{props.sensor.depth} {props.sensor.depthUnit}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" sx={{
              backgroundColor: "#7F7F7F",
              borderRight: "1px solid black",
            }}>
              Placement Date
            </StyledTableCell>
            <StyledTableCell align="right">{moment(props.sensor.placementDate).tz(props.placement.timeZone).format("MM/DD/YY H:mm")}</StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" sx={{
              backgroundColor: "#7F7F7F",
              borderRight: "1px solid black",
            }}>
              Sample Interval (minutes)
            </StyledTableCell>
            <StyledTableCell align="right">{props.sensor.sampleInterval}</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SensorInfoTableView