import React from "react"
import { Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material"
import Placement from "../../../models/placement"
import styled from "@emotion/styled"
import { convertedTemperatureValue } from "../../../utilities/calculations"
import Sensor, { calculateElapsedTimeString } from "../../../models/sensor"
import { maturityForSensor } from "../../../utilities/maturity"
import moment, { Moment } from "moment"
import { convertedGoalMaturityValue, convertedGoalValue } from "../../../models/goal"
import { convertToMaturityMethodEntry } from "../../../models/maturitymethod"
import { samplesAfterPlacementDate } from "../../../utilities/samples"
import Sample from "../../../models/sample"

interface Props {
  sensor: Sensor
  placement: Placement
  id: string
}

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#AAAAAA",
  },
}))

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#7F7F7F",
    whiteSpace: "nowrap",
    fontSize: 12
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 6,
    whiteSpace: "nowrap"
  },
  "&:not(:last-child)": {
    borderRight: "1px solid black"
  }
}))

const SensorSummaryTableView = (props: Props) => {
  const hasMaturity = props.placement.maturityMethod.type !== "disabled"
  const maturityUnit = props.placement.maturityMethod.type === "arrhenius" ? "hr" : (props.placement.maturityMethod.temperatureUnit === "c" ? "ºC-hr" : "ºF-hr")
  const hasStrength = props.placement.maturityMethod.curveFit.type !== "disabled"

  const maturityData = maturityForSensor(props.sensor, props.placement)
  const samples = samplesAfterPlacementDate(props.sensor)
  
  let minTempSample: Sample | null = null
  let maxTempSample: Sample | null = null
  let mostRecentSample: Sample | null = null

  if (samples.length > 0) {
    const reversed_samples = samples.reverse()
    minTempSample = reversed_samples.reduce((prev, current) => (prev.temperature < current.temperature) ? prev : current)
    maxTempSample = reversed_samples.reduce((prev, current) => (prev.temperature > current.temperature) ? prev : current)
    mostRecentSample = reversed_samples[0]
  }

  const maturityAtTime = (time: Moment) => {
    return maturityData.find((data) => data.sample.time === time)
  }

  return (
    <TableContainer id={props.id} component={Paper} sx={{
      width: "100%",
      overflow: "hidden"
    }}>
      <Table sx={{
        border: "2px solid black"
      }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>
            </StyledTableCell>
            <StyledTableCell>
              Time ({props.placement.timeZone})
            </StyledTableCell>
            <StyledTableCell>
              Elapsed Time (d.hh:mm)
            </StyledTableCell>
            <StyledTableCell>
              Temperature ({props.placement.temperatureUnit === "c" ? "ºC" : "ºF"})
            </StyledTableCell>
            {hasMaturity && 
            <>
              <StyledTableCell>
                TTF ({maturityUnit})
              </StyledTableCell>
              {hasStrength &&
              <>
                <StyledTableCell>
                  Strength ({props.placement.maturityMethod.curveFit.parameters?.strengthUnit === "psi" ? "psi" : "MPa"})
                </StyledTableCell>
              </> 
              }
            </>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {props.placement.goals
            .map((goal) => {
              const maturityMethodEntry = convertToMaturityMethodEntry(props.placement.maturityMethod)
              const goalMaturityValue = convertedGoalMaturityValue(goal, maturityMethodEntry)
              const maturity = maturityData.find((data) => data.maturityValue >= goalMaturityValue)

              if (!maturity) {
                return <></>
              } else {
                return (
                  <StyledTableRow key={goal.name}>
                    <StyledTableCell component="th" scope="row" sx={{
                      backgroundColor: "#7F7F7F",
                      borderRight: "1px solid black",
                    }}>
                      {goal.name} - {convertedGoalValue(goal, maturityMethodEntry).toFixed(0)}
                    </StyledTableCell>
                    <StyledTableCell align="right">{moment(maturity.sample.time).tz(props.placement.timeZone).format("MM/DD/YY H:mm")}</StyledTableCell>
                    <StyledTableCell align="right">{calculateElapsedTimeString(maturity.sample.time, moment(new Date(props.sensor.placementDate).setSeconds(0, 0)))}</StyledTableCell>
                    <StyledTableCell align="right">{convertedTemperatureValue(maturity.sample.temperature, props.placement.temperatureUnit).toFixed(1)}</StyledTableCell>
                    {hasMaturity && 
                    <>
                      <StyledTableCell align="right">{maturity.maturityValue.toLocaleString(undefined, {maximumFractionDigits: 0})}</StyledTableCell>
                      {hasStrength &&
                      <>
                        <StyledTableCell align="right">{maturity.strengthValue?.toLocaleString(undefined, {maximumFractionDigits: 0})}</StyledTableCell>
                      </> 
                      }
                    </>
                    }
                  </StyledTableRow>
                )
              }
            })
          }
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" sx={{
              backgroundColor: "#7F7F7F",
              borderRight: "1px solid black",
            }}>
              Min Temp
            </StyledTableCell>
            <StyledTableCell align="right">{minTempSample ? moment(minTempSample.time).tz(props.placement.timeZone).format("MM/DD/YY H:mm") : "-"}</StyledTableCell>
            <StyledTableCell align="right">{minTempSample ? calculateElapsedTimeString(minTempSample.time, moment(new Date(props.sensor.placementDate).setSeconds(0, 0))) : "-"}</StyledTableCell>
            <StyledTableCell align="right">{minTempSample ? convertedTemperatureValue(minTempSample.temperature, props.placement.temperatureUnit).toFixed(1) : "-"}</StyledTableCell>
            {hasMaturity &&
            <>
              <StyledTableCell align="right">{minTempSample ? maturityAtTime(minTempSample.time)?.maturityValue.toFixed(0) : "-"}</StyledTableCell>
              {hasStrength &&
              <>
                <StyledTableCell align="right">{minTempSample ? maturityAtTime(minTempSample.time)?.strengthValue?.toFixed(0) : "-"}</StyledTableCell>
              </> 
              }
            </>
            }
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" sx={{
              backgroundColor: "#7F7F7F",
              borderRight: "1px solid black",
            }}>
              Max Temp
            </StyledTableCell>
            <StyledTableCell align="right">{maxTempSample ? moment(maxTempSample.time).tz(props.placement.timeZone).format("MM/DD/YY H:mm") : "-"}</StyledTableCell>
            <StyledTableCell align="right">{maxTempSample ? calculateElapsedTimeString(maxTempSample.time, moment(new Date(props.sensor.placementDate).setSeconds(0, 0))) : "-"}</StyledTableCell>
            <StyledTableCell align="right">{maxTempSample ? convertedTemperatureValue(maxTempSample.temperature, props.placement.temperatureUnit).toFixed(1) : "-"}</StyledTableCell>
            {hasMaturity &&
            <>
              <StyledTableCell align="right">{maxTempSample ? maturityAtTime(maxTempSample.time)?.maturityValue.toFixed(0) : "-"}</StyledTableCell>
              {hasStrength &&
              <>
                <StyledTableCell align="right">{maxTempSample ? maturityAtTime(maxTempSample.time)?.strengthValue?.toFixed(0) : "-"}</StyledTableCell>
              </> 
              }
            </>
            }
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row" sx={{
              backgroundColor: "#7F7F7F",
              borderRight: "1px solid black",
            }}>
              Download
            </StyledTableCell>
            <StyledTableCell align="right">{mostRecentSample ? moment(mostRecentSample.time).tz(props.placement.timeZone).format("MM/DD/YY H:mm") : "-"}</StyledTableCell>
            <StyledTableCell align="right">{mostRecentSample ? calculateElapsedTimeString(mostRecentSample.time, moment(new Date(props.sensor.placementDate).setSeconds(0, 0))) : "-"}</StyledTableCell>
            <StyledTableCell align="right">{mostRecentSample ? convertedTemperatureValue(mostRecentSample.temperature, props.placement.temperatureUnit).toFixed(1) : "-"}</StyledTableCell>
            {hasMaturity &&
            <>
              <StyledTableCell align="right">{mostRecentSample ? maturityAtTime(mostRecentSample.time)?.maturityValue.toFixed(0) : "-"}</StyledTableCell>
              {hasStrength &&
              <>
                <StyledTableCell align="right">{mostRecentSample ? maturityAtTime(mostRecentSample.time)?.strengthValue?.toFixed(0) : "-"}</StyledTableCell>
              </> 
              }
            </>
            }
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SensorSummaryTableView