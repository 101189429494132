import React from "react"
import { Box, Button, List, ListItem, TextField } from "@mui/material"
import { PlacementEntry } from "../../../models/placement"
import { calculateMaturityFromStrength } from "../../../utilities/maturity"
import { GoalEntry } from "../../../models/goal"

interface Props {
  entry: PlacementEntry
  onGoalsChange: (goals: GoalEntry[]) => void
  disabled?: boolean
  highlightRequiredFields?: boolean
}

const GoalView = (props: Props) => {

  if (props.entry.maturityMethod.type === "disabled") {
    return null
  }

  if (!props.entry.maturityMethod.temperatureUnit) {
    return null
  }

  const goals = props.entry.goals
  const maturityUnit = props.entry.maturityMethod.type === "arrhenius" ? "hr" : `º${props.entry.maturityMethod.temperatureUnit.toUpperCase()}-hr`

  const defaultGoalValue = (goal: GoalEntry) => {
    if (!goal.value) {
      return null
    }

    if (props.entry.maturityMethod.curveFit.type === "disabled") {
      return goal.value.toFixed(0)
    } else {
      if (props.entry.maturityMethod.curveFit.parameters.strengthUnit === "psi") {
        return goal.value.toFixed(0)
      } else {
        return goal.value.toFixed(2)
      }
    }
  }

  const handleGoalRemove = (index: number) => {
    goals.splice(index, 1)
    props.onGoalsChange(goals)
  }

  return (
    <>
      <List sx={{
        padding: "0px 0px",
        marginTop: "24px",
        width: "100%"
      }}>
        {goals.map((goal, i) => {
          return (
            <ListItem sx={{
              paddingLeft: "0px",
              paddingRight: "0px",
              paddingBottom: "24px"
            }} key={goal.id}>
              <Box display="flex" flexDirection="column" sx={{ 
                width: "100%"
              }}>
                <List sx={{
                  padding: "0px",
                  width: "100%"
                }}>
                  <ListItem sx={{
                    paddingLeft: "0px",
                    paddingRight: "0px"
                  }}>
                    <TextField
                      label="Goal Name"
                      value={goal.name}
                      onChange={(e) => {
                        goals[i].name = e.target.value
                        props.onGoalsChange(goals)
                      }}
                      fullWidth
                      disabled={props.disabled}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </ListItem>
                  <ListItem sx={{
                    paddingLeft: "0px",
                    paddingRight: "0px"
                  }}>
                    <TextField
                      key={`goal-${i}`}
                      placeholder="Required"
                      label={props.entry.maturityMethod.curveFit.type === "disabled" ? `Maturity (${maturityUnit})` : `Strength (${props.entry.maturityMethod.curveFit.parameters.strengthUnit})`}
                      defaultValue={defaultGoalValue(goal)}
                      onChange={(e) => {
                        goals[i].value = parseFloat(e.target.value)
                        props.onGoalsChange(goals)
                      }}
                      fullWidth
                      error={props.highlightRequiredFields && !goal.value}
                      disabled={props.disabled}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </ListItem>
                  {props.entry.maturityMethod.curveFit.type !== "disabled" &&
                    <ListItem
                      sx={{
                        paddingLeft: "0px",
                        paddingRight: "0px"
                      }}>
                      <TextField
                        key={`maturity-${i}`}
                        size="small"
                        label={`Relative Maturity (${maturityUnit})`}
                        value={goal.value && calculateMaturityFromStrength(props.entry.maturityMethod, goal.value)?.toFixed(0)}
                        disabled
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </ListItem>
                  }
                </List>
                <Button
                  variant="text"
                  color="primary"
                  sx={{ 
                    textTransform: "none",
                    fontWeight: "normal",
                    background: "none",
                    padding: "0px",
                    whiteSpace: "nowrap",
                    minWidth: "auto",
                    alignSelf: "flex-end",
                    ":hover": { background: "none" }
                  }}
                  onClick={() => handleGoalRemove(i)}
                >
                  Remove Goal
                </Button>
              </Box>
            </ListItem>
          )
        })}
      </List>
    </>
  )
}

export default GoalView
