import { Box, Tab, Tabs, Typography, Link, Button } from "@mui/material"
import React, { useState } from "react"
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import CredentialsForm from "./CredentialsForm"
import { useAuth } from "./AuthProvider"
import { logSignUp } from "../../utilities/analytics"

const SignIn = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    loginWithUsernamePassword,
    signUp,
    resetPassword
  } = useAuth()
  
  const getCurrentTab = () => {
    if (location.pathname.endsWith("signin")) {
      return 0
    } else if (location.pathname.endsWith("signup")) {
      return 1
    }
    return null
  }

  const [error, setError] = useState<string | null>(null)
  const [resetPasswordRequested, setResetPasswordRequested] = useState<boolean>(false)

  const [searchParams] = useSearchParams()
  const redirectPath = searchParams.get("redirect")

  const setCurrentTab = (tab: number) => {
    setError(null)

    if (tab === 0) {
      navigate(`/signin${location.search}`)
    } else {
      navigate(`/signup${location.search}`)
    }
  }

  const handleSignIn = (email: string, password: string) => {
    loginWithUsernamePassword(email, password, redirectPath)
      .catch((e) => {
        console.error(e)
        setError(e.response.data.error_description)
      })
  }

  const handleSignUp = (email: string, password: string) => {
    signUp(email, password, redirectPath)
      .then(() => {
        logSignUp(email)
      })
      .catch((e) => {
        console.error(e)
        if (e.response.data.code == "user_exists") {
          setError("An account already exists for this email.")
        } else if (e.response.data.code == "invalid_password") {
          setError("Your password must have at least 8 characters.")
        } else if (e.response.data.error.startsWith("error in email")) {
          setError("Invalid email address.")
        } else {
          setError(e.response.data.description)
        }
      })
  }

  const handleForgotPassword = (email: string) => {
    resetPassword(email)
      .then(() => {
        setResetPasswordRequested(true)
      })
      .catch((e) => {
        console.error(e)
        setError(e.response.data.message)
      })
  }

  // Remove any trailing slashes from the location path
  if (location.pathname !== "/" && location.pathname.endsWith("/")) {
    window.location.replace(location.pathname.replace(/\/+$/, ""))
  }

  return (
    <Box sx={{
      width: "100%",
      textAlign: "center",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%) translateX(50%)",
      right: "50%",
      maxWidth: "440px"
    }}>
      <Box
        component="img"
        sx={{
          width: 250,
          maxWidth: { xs: 150, md: 200 },
        }}
        alt="The house from the offer."
        src="https://www.commandcenterconcrete.com/wp-content/uploads/2020/12/CC_Illustration_Cloud-Icon-946x1024.png"
      />
      {getCurrentTab() !== null &&
        <Box display="flex" alignItems="center" justifyContent="space-around">
          <Tabs variant="fullWidth" value={getCurrentTab()} onChange={(event, newValue) => setCurrentTab(newValue)} sx={{
            marginRight: "30px",
            marginLeft: "30px",
            maxWidth: "330px",
            width: "100%",
          }}>
            <Tab label="Login" value={0} />
            <Tab label="Sign Up" value={1} />
          </Tabs>
        </Box>
      }
      <Routes>
        <Route path="/signin" element={
          <CredentialsForm buttonTitle='Sign In' error={error} onSubmit={handleSignIn}>
            <Link
              type="button"
              component="button"
              variant="body2"
              onClick={() => {
                navigate("/forgot-password")
              }}
              sx={{     
                textDecoration: "none",
                paddingTop: "20px"
              }}
            >
              <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                Don&apos;t remember your password?
              </Typography>
            </Link>
          </CredentialsForm>
        }/>
        <Route path="/signup" element={
          <CredentialsForm buttonTitle='Sign Up' error={error} onSubmit={handleSignUp}>
            <Typography variant="subtitle1" sx={{ 
              textAlign: "center",
              textDecoration: "none",
              paddingTop: "20px"
            }}>
              By signing up you agree to our <a href="https://www.commandcenterconcrete.com/eula/" target="blank">terms of service</a> and <a href="https://app.termly.io/document/privacy-policy/1dda1d8d-77ca-4a60-a8c4-72d071fcc41b" target="blank">privacy policy</a>        
            </Typography>
          </CredentialsForm>
        }/>
        <Route path="/forgot-password" element={
          resetPasswordRequested ?
            <Box>
              <Typography variant="subtitle1" sx={{ 
                textAlign: "center",
                paddingTop: "20px"
              }}>
                We have sent you an email with instructions to reset your password
              </Typography>
              <Button variant="contained" color="primary" sx={{
                textTransform: "none",
                borderRadius: "30px",
                width: "calc(100% - 60px)",
                fontWeight: "bold",
                fontSize: "initial",
                margin: "30px"
              }} onClick={() => {
                navigate("/signin")
              }}>
                Back to sign in
              </Button>
            </Box> :
            <CredentialsForm buttonTitle='Reset Password' hidePasswordField={true} error={error} onSubmit={handleForgotPassword}>
              <Typography variant="subtitle1" sx={{ 
                textAlign: "center",
                textDecoration: "none",
                paddingTop: "20px"
              }}>
                Enter your email address and we will send you a link to reset your password
              </Typography>
            </CredentialsForm>
        }/>
        <Route path="*" element={
          <Navigate to={
            `/signin${location.pathname === "/" ? "" : `?redirect=${ location.pathname}`}`
          } relative="path" />
        }/>
      </Routes>
    </Box>
  )
}

export default SignIn